<template>
  <div
    :id="'component-base-wrapper-'+id"
    :class="'relative pb-4 print:min-w-full ' + customClasses"
  >
    <div v-if="showComponent">
      <component
        :is="componentMapping.get(relevantDisplayType)"
        :id="'component-base-actual-'+id"
        :key="'component-base-actual-'+id"
        ref="child"
        :header="relevantReportDefinition"
        v-bind="boundProperties"
        :settings="parsedReportData?.data?.settings ?? parsedReportData?.settings"
        :query-params="allQueryParams"
        :dashboard-id="dashboardId"
        @before-zoom="$emit('before-zoom', { ...$event })"
        @reset-zoom="$emit('reset-zoom')"
      />
    </div>
    <div v-else>
      <component
        :is="skeletonComponentMapping.get(relevantDisplayType) || skeletonComponentMapping.get('list')"
        ref="skeleton"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { truMetricsArray, type TruMetric, type HubFilter } from '~/types'
import type { HubDashboardComponentType, ServerDataResponse, TruHubDashboardComponent } from '~/types/component'
import type { FilterBarItem, HubComponentConfig } from '~/types/configuration'
import { formatQueryParamsForAPI } from '~/utils/api-helpers'

interface ComponentProps extends HubComponentConfig {
  id: string
  theme: 'mono' | 'colour'
  dashboardId: string
  sectionFilters?: { [name: string]: string | number | undefined }
  sectionFilterBarConfig?: Array<FilterBarItem>
}

const props = defineProps<ComponentProps>()
const emit = defineEmits<{
  (e: 'error', arg1: unknown): void
  (e: 'before-zoom', { minX, maxX }: { minX: EpochTimeStamp, maxX: EpochTimeStamp }): void
  (e: 'reset-zoom'): void
}>()

const componentMapping = new Map<HubDashboardComponentType, TruHubDashboardComponent>([
  ['link', defineAsyncComponent(() => import('@/components/hub/HubLink.vue'))],
  ['promoter', defineAsyncComponent(() => import('~/components/hub/HubPromoter.vue'))],
  ['leaderboard', defineAsyncComponent(() => import('@/components/hub/leaderboard/HubLeaderboard.vue'))],
  ['keyDayParts', defineAsyncComponent(() => import('@/components/hub/HubKeyDayParts.vue'))],
  ['statistic', defineAsyncComponent(() => import('@/components/hub/statistic/HubStatistic.vue'))],
  ['list', defineAsyncComponent(() => import('@/components/hub/statistic/HubStatisticList.vue'))],
  ['table', defineAsyncComponent(() => import('@/components/hub/table/HubTable.vue'))],
  ['tableList', defineAsyncComponent(() => import('@/components/hub/table/HubTableList.vue'))],
  ['lineChart', defineAsyncComponent(() => import('@/components/hub/chart/HubChartLine.vue'))],
  ['barChart', defineAsyncComponent(() => import('@/components/hub/chart/HubChartBar.vue'))],
  ['timeBarChart', defineAsyncComponent(() => import('@/components/hub/chart/HubChartTimeBar.vue'))],
  ['heatmapChart', defineAsyncComponent(() => import('@/components/hub/chart/HubChartHeatMap.vue'))],
  ['pieChart', defineAsyncComponent(() => import('@/components/hub/chart/HubChartPie.vue'))],
  ['commentSentiment', defineAsyncComponent(() => import('@/components/comment/CommentSentiments.vue'))],
  ['commentMention', defineAsyncComponent(() => import('@/components/comment/CommentMentions.vue'))]
])
const skeletonComponentMapping = new Map<HubDashboardComponentType, TruHubDashboardComponent>([
  ['list', defineAsyncComponent(() => import('@/components/skeleton/SkeletonList.vue'))],
  ['leaderboard', defineAsyncComponent(() => import('@/components/skeleton/SkeletonLeaderboard.vue'))],
  ['table', defineAsyncComponent(() => import('@/components/skeleton/SkeletonTable.vue'))],
  ['tableList', defineAsyncComponent(() => import('@/components/skeleton/SkeletonTableList.vue'))],
  ['keyDayParts', defineAsyncComponent(() => import('@/components/skeleton/SkeletonKeyDayParts.vue'))],
  ['heatmapChart', defineAsyncComponent(() => import('@/components/skeleton/chart/SkeletonChartHeatmap.vue'))],
  ['lineChart', defineAsyncComponent(() => import('@/components/skeleton/chart/SkeletonChartLine.vue'))],
  ['pieChart', defineAsyncComponent(() => import('@/components/skeleton/chart/SkeletonChartPie.vue'))],
  ['commentSentiment', defineAsyncComponent(() => import('@/components/skeleton/chart/SkeletonChartPie.vue'))],
  ['barChart', defineAsyncComponent(() => import('@/components/skeleton/chart/SkeletonChartBar.vue'))],
  ['timeBarChart', defineAsyncComponent(() => import('@/components/skeleton/chart/SkeletonChartBar.vue'))]
])

const filterStore = useFilterStore()
const { params } = storeToRefs(filterStore)
const { locale, t } = useI18n()

// local refs
const detailsEnabled: Ref<boolean> = ref(false)
const parsedReportData = ref()
const dataDisplayType: Ref<HubDashboardComponentType | undefined> = ref()

// computed refs
// -- component settings
const boundProperties = computed(() => {
  let boundProps = {}

  if (props.additionalOptions && !shouldUseFallbackReport.value) {
    boundProps = {
      ...boundProps,
      ...props.additionalOptions
    }
  }

  if (props.fallbackAdditionalOptions && shouldUseFallbackReport.value) {
    boundProps = {
      ...boundProps,
      ...props.fallbackAdditionalOptions
    }
  }

  boundProps = {
    ...boundProps,
    ...parsedReportData.value
  }

  const originalTheme = boundProps?.theme || 'colour'
  const theme = props.theme === 'mono' ? 'mono' : originalTheme

  return { ...boundProps, theme }
})
const showComponent: ComputedRef<boolean> = computed(() => {
  return (parsedReportData.value && dataDisplayType.value === relevantDisplayType.value)
    || relevantDisplayType.value === 'link'
})

// -- query param handling (computed refs)
const standardQueryParams: ComputedRef<{ [name: string]: string | number | undefined }> = computed(() => {
  let formattedQueryParams = formatQueryParamsForAPI(params.value as HubFilter)
  const formattedSectionFilters = props.sectionFilters ? formatQueryParamsForAPI(props.sectionFilters) : {}

  if (formattedSectionFilters) {
    formattedQueryParams = {
      ...formattedQueryParams,
      ...formattedSectionFilters
    }
  }
  return {
    cultureInfo: locale.value ? locale.value.split('-')[0] : undefined,
    ...formattedQueryParams
  }
})
const reportSpecificQueryParams: ComputedRef<{ [name: string]: string | number | undefined }> = computed(() => {
  if (!props.queryParams && !props.fallbackReportQueryParams) {
    return {}
  }

  if (shouldUseFallbackReport.value && props.fallbackReportQueryParams) {
    return props.fallbackReportQueryParams
  }

  return props.queryParams || {}
})
const allQueryParams: ComputedRef<{
  [name: string]: string | number | Array<string | number> | undefined
}> = computed(() => ({
  ...standardQueryParams.value,
  ...reportSpecificQueryParams.value
}))

// -- component configuration usage, which report and display type etc
const requiredFiltersAreSelected = computed(() => {
  if (!props.requiredFilters) {
    return true // no filters are required
  }

  if (props.allFiltersRequired) {
    return props.requiredFilters.every(filter => standardQueryParams.value[filter])
  }

  return props.requiredFilters.some(filter => standardQueryParams.value[filter])
})
const shouldUseFallbackReport: ComputedRef<boolean> = computed(() => {
  if (!props.fallbackReportDefinition || !props.requiredFilters) {
    return false
  }

  if (!standardQueryParams.value || objectIsEmpty(standardQueryParams.value)) {
    return true
  }

  // if required filters are not selected, then should use fallback report
  return !requiredFiltersAreSelected.value
})
const relevantReportDefinition: ComputedRef<string> = computed(() => {
  // It doesn't realise that shouldUseFallbackReport checks for the fallbackReportDefinition
  if (shouldUseFallbackReport.value && props.fallbackReportDefinition) {
    return props.fallbackReportDefinition
  }

  if (props.detailedReportDefinition && detailsEnabled.value) {
    return props.detailedReportDefinition
  }

  return props.reportDefinition
})
const isFakeReportDefinition: ComputedRef<boolean> = computed(() => {
  return relevantReportDefinition.value.includes('Fake')
})
const relevantDisplayType: ComputedRef<HubDashboardComponentType> = computed(() => {
  if (isFakeReportDefinition.value && props.fallbackReportDisplayType) {
    return props.fallbackReportDisplayType
  }

  return props.displayType
})

// -- metric filtering helpers
const isInStore: ComputedRef<boolean> = computed(() => {
  return !!(allQueryParams.value.touchpoint
    && (
      (Array.isArray(allQueryParams.value.touchpoint) && allQueryParams.value.touchpoint.includes('instore'))
      || allQueryParams.value.touchpoint === 'instore')
  )
})
const relevantTruMetrics: ComputedRef<Array<TruMetric>> = computed(() => {
  return truMetricsArray.filter(
    truMetric =>
      truMetric.name !== 'trurating'
      && (isInStore.value ? truMetric.name !== 'ease-of-use' : truMetric.name !== 'service')
  )
})

// watchers
watch(
  [
    () => allQueryParams,
    () => relevantReportDefinition,
    () => relevantDisplayType
  ],
  () => {
    getComponentData()
  },
  {
    immediate: true,
    deep: true
  }
)

// functions
async function fetchData(
  reportDefinition: string,
  customQueryParams?: { [name: string]: string | number | undefined }
): Promise<ServerDataResponse> {
  const response = await $hubFetch(`api/v4/dashboards/${props.dashboardId}/reports/${reportDefinition}`, {
    query: { ...allQueryParams.value, ...customQueryParams }
  })

  return response
}

async function exportData(
  reportDefinition: string,
  customQueryParams?: { [name: string]: string | number | undefined },
  customFileName?: string
) {
  const data = await $hubFetch(
    `api/v4/dashboards/${props.dashboardId}/reports/${reportDefinition}`,
    {
      query: { ...allQueryParams.value, ...customQueryParams },
      responseType: 'blob'
    },
    'xlsx'
  )

  createExportFile(data, (customFileName || reportDefinition) + '-' + new Date().toJSON().slice(0, 10), 'xlsx', true)
}

// async functions
function exportComponentData() {
  if (props.displayType === 'link') {
    return
  }

  if (
    isFakeReportDefinition.value
    && relevantReportDefinition.value.includes('FakeCoreQuestion')
    && relevantReportDefinition.value.includes('All')
  ) {
    for (const metric of relevantTruMetrics.value) {
      exportData(
        props.reportDefinition,
        {
          TruMetric: metric.name
        },
        props.reportDefinition + '-' + metric.name
      )
    }

    return
  }

  exportData(relevantReportDefinition.value)
}

async function handleFakeReport() {
  let fakeServerResponse

  if (relevantReportDefinition.value.includes('FakeCoreQuestion') && relevantReportDefinition.value.includes('All')) {
    const collectionOfAPICalls = []

    for (const metric of relevantTruMetrics.value) {
      const fetchMethod = fetchData(props.reportDefinition, {
        TruMetric: metric.name
      })
      collectionOfAPICalls.push(fetchMethod)
    }

    const collectionOfResponses = await Promise.all(collectionOfAPICalls)

    fakeServerResponse = {
      rawApiParameters: collectionOfResponses[0].rawApiParameters,
      results: {
        columnHeadings: collectionOfResponses[0].results.columnHeadings,
        rows: collectionOfResponses.map(response => {
          const relevantMetric = relevantTruMetrics.value.find(
            truMetric => truMetric.name === response.rawApiParameters.truMetric
          )

          return {
            metric: {
              ...relevantMetric,
              displayName: t(`filters.truMetrics.${relevantMetric?.name}`)
            },
            rows: response.results.rows
          }
        })
      },
      _metaData: collectionOfResponses[0]._metaData
    }

    if (
      fakeServerResponse.results.rows.length === 0
      || fakeServerResponse.results.rows.every(row => row.rows.length === 0)
    ) {
      emit('error', {
        title: 'reports.errors.generic.header.lowData',
        statusMessage: 'reports.errors.generic.message.selectDifferentFilters'
      })
      return
    }
  }

  if (!fakeServerResponse) {
    return
  }

  // @ts-expect-error let it happen
  parsedReportData.value = parseReportData(fakeServerResponse, relevantDisplayType.value, true)
}

async function getComponentData() {
  if (!requiredFiltersAreSelected.value && !props.fallbackReportDefinition) {
    // if the required filters aren't selected and there's no fallback report definition
    // don't fetch data & make sure the component is empty
    parsedReportData.value = undefined
    return
  }

  if (dataDisplayType.value != relevantDisplayType.value) {
    parsedReportData.value = undefined
  }

  try {
    if (relevantDisplayType.value === 'link') {
      return
    }
    if (isFakeReportDefinition.value) {
      await handleFakeReport()
      dataDisplayType.value = relevantDisplayType.value
      return
    }

    let parsedData
    const data = await fetchData(relevantReportDefinition.value)

    if (data.results.rows.length === 0) {
      parsedReportData.value = undefined
      emit('error', {
        title: 'reports.errors.generic.header.lowData',
        statusMessage: 'reports.errors.generic.message.selectDifferentFilters'
      })
      return
    }

    if (relevantDisplayType.value.includes('comment')) {
      parsedData = parseCommentData(
        data as ServerDataResponse,
        relevantDisplayType.value.toLowerCase().split('comment')[1] as 'list' | 'mention' | 'sentiment'
      )

      // Manually add additional options for the comment components
      parsedData = {
        ...parsedData,
        alwaysShowHeading: true
      }
    } else {
      parsedData = parseReportData(data as ServerDataResponse, relevantDisplayType.value)
    }

    parsedReportData.value = parsedData
    dataDisplayType.value = relevantDisplayType.value
  } catch (error) {
    emit('error', error)
  }
}

defineExpose({
  exportComponentData
})
</script>
