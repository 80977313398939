<template>
  <div
    :id="touchpointId + ':' + sectionId + ':' + id"
    class="print:break-inside-avoid"
    :class="{ 'pb-8': (visible && sectionDisplayType === 'list') }"
  >
    <div
      class="flex items-center justify-between px-4 pb-4 pt-8 lg:px-6"
      :class="{ 'pb-8': !visible }"
    >
      <h3 class="text-md flex items-center justify-start font-semibold">
        <HubButton
          v-if="sectionDisplayType == 'list'"
          btn-style="print:hidden mr-4 bg-trublue rounded-full p-1.5"
          icon-only
          icon-classes="text-white"
          :icon="'i-mdi-' + (chevronVisible ? 'chevron-down' : 'chevron-right')"
          @click="toggleItemVisibility"
        />
        {{ translateLabel ? $t('dashboards.section.itemName.' + label) : label }}
        <UPopover
          v-if="tooltipTranslationKey"
          class="print:hidden"
          :popper="{ arrow: true, resize: true, scroll: false }"
          :mode="$viewport.isGreaterOrEquals('lg') ? 'hover' : 'click'"
          :ui="{
            width: 'w-[230px]',
            base: 'h-auto text-sm text-clip text-wrap py-2 px-3',
            popper: { strategy: 'fixed' },
          }"
        >
          <HubButton
            btn-style="rounded-full p-1.5 bg-transparent"
            icon-only
            icon="i-mdi-information-outline"
            icon-classes="text-gray-500"
          />
          <template #panel>
            <div class="text-sm font-normal">
              {{ $t(`dashboards.section.tooltip.${tooltipTranslationKey}`) }}
            </div>
          </template>
        </UPopover>
      </h3>
      <div
        v-if="!componentError"
        class="flex items-center space-x-4 print:hidden"
      >
        <!-- TODO: Add detailed view in Alpha -->
        <HubButton
          v-if="components.some((x: HubComponentConfig) => x.detailedReportDefinition) && false"
          icon-only
          icon="i-mdi-information-outline"
          btn-style="rounded-full p-1.5 bg-transparent"
          icon-classes="text-trublue"
        />
        <!-- TODO: Add settings in Alpha -->
        <HubButton
          v-if="false"
          icon-only
          icon="i-mdi-cog-outline"
          btn-style="rounded-full p-1.5 bg-transparent"
          icon-classes="text-trublue h-auto w-5"
        />

        <button
          v-if="showChartThemeToggle"
          @click="toggleChartTheme"
        >
          <img
            :src="theme === 'colour' ? monoChartTheme : defaultChartTheme"
            alt="Chart Theme"
            class="-mb-1 h-5 w-auto lg:mb-0"
          >
        </button>

        <HubButton
          v-if="visible"
          icon-only
          icon="i-mdi-tray-arrow-down"
          btn-style="rounded-full p-1.5 bg-transparent"
          icon-classes="text-trublue print:hidden !min-h-5 !w-5"
          @click="exportData"
        />
      </div>
    </div>
    <transition
      enter-from-class="opacity-0"
      leave-to-class="opacity-0"
      enter-active-class="transition duration-300"
      leave-active-class="transition duration-300"
      @before-enter="toggleChevronVisibility(true)"
      @after-leave="toggleChevronVisibility(false)"
    >
      <div v-if="visible">
        <div
          v-if="filterBarConfig && filterBarConfig.length"
          class="mx-4 mt-2 flex justify-start gap-4 bg-trublue-50/75 px-2 pt-2 lg:mx-6"
        >
          <DashboardSectionFilter
            v-for="filter in filterBarConfig"
            :key="filter.filterName"
            v-model:model-value="filter.value"
            v-model:grouped-item-value="
              // @ts-ignore
              filter.groupedItemValue
            "
            :filter-item="filter"
            :dashboard-id="dashboardId"
            :parent-filters="props.touchpointFilters"
            class="w-full"
          />
        </div>

        <div :class="'px-4 lg:px-6 pt-4 ' + (componentError ? '' : customClasses)">
          <NuxtErrorBoundary>
            <DashboardComponentError
              v-if="componentError"
              v-model:error="componentError"
              :error-count="errorCount"
            />

            <template v-else>
              <DashboardComponentBase
                v-for="component in shownComponents"
                :id="id + '-' + component.reportDefinition + '-' + component.displayType"
                ref="componentRefs"
                :key="id + '-' + component.reportDefinition + '-' + component.displayType"
                :dashboard-id="dashboardId"
                :section-filters="appliedFilters"
                :section-filter-bar-config="filterBarConfig"
                :theme="theme"
                v-bind="component"
                @error="componentError = $event; errorCount++"
                @before-zoom="updateLocalDateFilter"
                @reset-zoom="localFilters = { dateFrom: undefined, dateTo: undefined }"
              />
            </template>

            <template #error="{ error: nuxtError, clearError }">
              <DashboardComponentError
                ref="nuxtErrorRef"
                :error="nuxtError"
                :clear-error="clearError"
                :error-count="errorCount"
              />
            </template>
          </NuxtErrorBoundary>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
// purgecss lg:grid lg:grid-cols-3 gap-4 lg:col-span-2

import type { EndpointFilterBarItem, HubComponentConfig, HubSectionItemConfig } from '~/types/configuration'
import defaultChartTheme from '~/assets/img/default-theme.svg'
import monoChartTheme from '~/assets/img/mono-theme.svg'
import type { CustomErrorType } from '~/types'

interface SectionItemProps extends HubSectionItemConfig {
  dashboardId: string
  sectionId: string
  sectionDisplayType: 'tabs' | 'list'
  touchpointId: string
  touchpointFilters?: { [name: string]: string | number | undefined }
}

const props = defineProps<SectionItemProps>()

// store
const filterStore = useFilterStore()
const { params } = storeToRefs(filterStore)

// local refs
const visible: Ref<boolean> = ref(true)
const chevronVisible: Ref<boolean> = ref(true)
const componentRefs = ref([])
const theme: Ref<'colour' | 'mono'> = ref('colour')
const errorCount: Ref<number> = ref(0)
const componentError: Ref<CustomErrorType | undefined> = ref()
const nuxtErrorRef = ref()
const localFilters: Ref<{ [name: string]: string | number | undefined }> = ref({})

// computed refs
const showChartThemeToggle = computed(() =>
  shownComponents.value.some(
    (c: HubComponentConfig) =>
      (c.displayType.includes('Chart') || c.displayType === 'commentSentiment')
      && (!c.reportDefinition.toLowerCase().includes('customquestion') || c.displayType.toLowerCase().includes('heat'))
  ) && visible.value
)
const shownComponents: ComputedRef<Array<HubComponentConfig>> = computed(() => {
  // If there are custom questions then we need to work out if we need to show the scaled or binary version

  if (
    appliedFilters.value
    && Object.prototype.hasOwnProperty.call(appliedFilters.value, 'responseOption')
    && appliedFilters.value.responseOption !== undefined
  ) {
    return props.components.filter(c => {
      const lowerCaseReportName = c.reportDefinition.toLowerCase()

      if (lowerCaseReportName.includes('customquestion')) {
        return !lowerCaseReportName.includes('scaled')
      }

      return true
    })
  }

  return props.components.filter(c => {
    const lowerCaseReportName = c.reportDefinition.toLowerCase()

    if (lowerCaseReportName.includes('customquestion')) {
      return !lowerCaseReportName.includes('binary')
    }

    return true
  })
})
const appliedFilters: ComputedRef<{ [name: string]: string | number | undefined }> = computed(() => {
  const filters: { [name: string]: string | number | undefined } = { ...props.touchpointFilters, ...localFilters.value }

  if (props.filterBarConfig) {
    for (const filter of props.filterBarConfig) {
      filters[filter.filterName] = filter.value as string | number | undefined

      if (
        Object.prototype.hasOwnProperty.call(filter, 'groupedItemValue')
        && Object.prototype.hasOwnProperty.call(filter, 'groupedItemFilterName')
      ) {
        const endpointFilter = filter as EndpointFilterBarItem
        // @ts-expect-error we're checking for the properties above
        filters[endpointFilter.groupedItemFilterName] = endpointFilter.groupedItemValue
      }
    }
  }

  return filters
})

// watchers
watch(
  [() => props, () => appliedFilters.value, () => params.value],
  () => {
    componentError.value = undefined
    nuxtErrorRef.value?.clearComponentError()
  },
  {
    deep: true,
    immediate: true
  }
)

// functions
function toggleItemVisibility() {
  visible.value = !visible.value
}

function toggleChevronVisibility(visible: boolean) {
  chevronVisible.value = visible
}

function toggleChartTheme() {
  const newValue = theme.value === 'colour' ? 'mono' : 'colour'

  theme.value = newValue
}
function exportData() {
  for (const component of componentRefs.value) {
    // @ts-expect-error we know that this exists & works
    component.exportComponentData()
  }
}
function updateLocalDateFilter({ minX, maxX }: { minX: EpochTimeStamp, maxX: EpochTimeStamp }) {
  // format dates properly, because apparently Canada is the right place to be (it's yyyy-mm-dd)
  const dateFrom = new Date(minX).toLocaleDateString('en-CA')
  const dateTo = new Date(maxX).toLocaleDateString('en-CA')

  localFilters.value = {
    ...localFilters.value,
    dateFrom,
    dateTo
  }
}
</script>
