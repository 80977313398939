<template>
  <div>
    <div class="border-b-[0.5px] border-b-zinc-200">
      <div class="m-4 text-lg font-medium">
        {{ dashboardObjectDeepClone.translateName
          ? $t('dashboards.name.' + dashboardObjectDeepClone.name)
          : dashboardObjectDeepClone.name }}
      </div>
    </div>

    <HubTabs
      v-if="dashboardObjectDeepClone.config.touchpointDisplayType == 'tabs'"
      :items="computedTouchpoints"
    >
      <template #item="{ item }">
        <DashboardTouchpoint
          :dashboard="dashboardObjectDeepClone"
          :touchpoint="(item as HubTouchpointConfig)"
          @update-section="updateSection"
          @update-touchpoint="updateTouchpoint"
        />
      </template>
    </HubTabs>
    <DashboardTouchpoint
      v-for="touchpoint in computedTouchpoints"
      v-else
      :key="touchpoint.id"
      :dashboard="dashboardObjectDeepClone"
      :touchpoint="touchpoint"
      @update-section="updateSection"
      @update-touchpoint="updateTouchpoint"
    />
  </div>
</template>

<script setup lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import type { HubDashboard, HubSectionConfig, HubTouchpointConfig } from '~/types/configuration'

const props = defineProps<{ dashboardObject: HubDashboard }>()

const { t } = useI18n()
const route = useRoute()
const touchpointStore = useTouchpointStore()
const sectionStore = useSectionStore()
const { currentOrganisationTouchpoints } = storeToRefs(touchpointStore)

const filterStore = useFilterStore()
const { params } = storeToRefs(filterStore)

// internal refs
const dashboardObjectDeepClone = ref(cloneDeep(props.dashboardObject))
const filteredTouchpointIds = ref<Array<string>>([])
const filteredTouchpoints = ref<Array<HubTouchpointConfig>>([])

// init + check dashboard exists
await touchpointStore.fetchTouchpointForOrganisation(dashboardObjectDeepClone.value.rootOrganisationNodeId)
// needs to be awaited, otherwise the cloneDeep call in DashboardTouchpoint may happen to quickly
await sectionStore.fetchSections()

// watchers
watch(
  () => props.dashboardObject,
  () => {
    dashboardObjectDeepClone.value = cloneDeep(props.dashboardObject)
    filteredTouchpoints.value = calculateTouchpoints()
  },
  {
    deep: true,
    immediate: true
  }
)
watch(
  [() => currentOrganisationTouchpoints.value, () => dashboardObjectDeepClone.value],
  () => {
    filteredTouchpoints.value = calculateTouchpoints()
  },
  {
    deep: true
  }
)
watch(
  () => params.value,
  () => {
    if (!params.value.touchpointIds) {
      filteredTouchpointIds.value = []
      return
    }

    const touchpointIds = params.value.touchpointIds
    if (Array.isArray(touchpointIds)) {
      filteredTouchpointIds.value = touchpointIds as Array<string>
      return
    }

    filteredTouchpointIds.value = [touchpointIds as string]
  },
  {
    deep: true,
    immediate: true
  }
)

// computed
const computedTouchpoints = computed(() => {
  return filteredTouchpoints.value.filter(t => {
    if (filteredTouchpointIds.value.length > 0) {
      return filteredTouchpointIds.value.includes(t.id) && t.isEnabled
    }

    return t.isEnabled
  }).sort((a, b) => a.ordinal - b.ordinal)
})

// methods
function calculateTouchpoints(): Array<HubTouchpointConfig> {
  const touchpoints: Array<HubTouchpointConfig> = []

  if (!dashboardObjectDeepClone.value?.config) {
    return currentOrganisationTouchpoints.value
  }

  for (const touchpoint of currentOrganisationTouchpoints.value) {
    if (!dashboardObjectDeepClone.value.config.touchpoints) {
    // if there are no modifications to touchpoints in the dashboard config, show all touchpoints
      touchpoints.push(touchpoint)
      continue
    }

    const touchpointOverride = dashboardObjectDeepClone.value.config.touchpoints.find(t => t.id === touchpoint.id)

    if (!touchpointOverride) {
      touchpoints.push(touchpoint)
      continue
    }

    const customisedTouchpoint: HubTouchpointConfig = {
      ...touchpoint,
      ...touchpointOverride
    }

    touchpoints.push(customisedTouchpoint)
  }

  return touchpoints
}

function updateSection<HubSectionConfigKey extends keyof HubSectionConfig>(
  sectionId: string,
  key: HubSectionConfigKey,
  value: HubSectionConfig[HubSectionConfigKey]
) {
  if (!dashboardObjectDeepClone.value.config.sections) {
    dashboardObjectDeepClone.value.config.sections = []
  }

  const sectionIndex: number = dashboardObjectDeepClone.value.config.sections.findIndex(s => s.id === sectionId)

  if (sectionIndex < 0) {
    dashboardObjectDeepClone.value.config.sections.push({
      id: sectionId,
      [key]: value
    })
  } else {
    dashboardObjectDeepClone.value.config.sections[sectionIndex] = {
      ...props.dashboardObject.config.sections[sectionIndex],
      // Required, or props.dashboardObject.config.sections increased in length forever
      id: sectionId,
      [key]: value
    }
  }

  debouncedDashboardUpdate()
}

function updateTouchpoint<HubTouchpointConfigKey extends keyof HubTouchpointConfig>(
  touchpointId: string,
  key: HubTouchpointConfigKey,
  value: HubTouchpointConfig[HubTouchpointConfigKey]
) {
  if (!dashboardObjectDeepClone.value.config.touchpoints) {
    dashboardObjectDeepClone.value.config.touchpoints = []
  }

  const touchpointIndex: number = dashboardObjectDeepClone.value.config.touchpoints.findIndex(
    t => t.id === touchpointId
  )

  if (touchpointIndex < 0) {
    dashboardObjectDeepClone.value.config.touchpoints.push({
      id: touchpointId,
      [key]: value
    })
  } else {
    dashboardObjectDeepClone.value.config.touchpoints[touchpointIndex] = {
      ...props.dashboardObject.config.touchpoints[touchpointIndex],
      // Required, or props.dashboardObject.config.touchpoints increased in length forever
      id: touchpointId,
      [key]: value
    }
  }

  debouncedDashboardUpdate()
}

const debouncedDashboardUpdate = debounce(async () => {
  if (!props.dashboardObject.isOwner) {
    // don't update by default if the user doesn't own this dashboard
    // TODO: Add new dashboard with current config if the user presses a 'Save' button
    return
  }

  try {
    const { data, error } = await useHubFetch<HubDashboard>(`api/v4/dashboards/${route.params.id}`, {
      method: 'PUT',
      body: {
        ordinal: props.dashboardObject.ordinal,
        name: props.dashboardObject.name,
        config: dashboardObjectDeepClone.value.config,
        rootOrganisationNodeId: props.dashboardObject.rootOrganisationNodeId
      }
    })

    if (!data.value) {
      throw new Error(error.value?.message)
    }

    useHubToast(t('dashboards.notifications.updated'), 'success')
  } catch (error: any) {
    let description: string = ''

    if (error.message.includes('403')) {
      description = t('dashboards.notifications.permissionDenied')
    }

    useHubToast(
      {
        title: t('dashboards.notifications.updateFailed'),
        description
      },
      'danger'
    )
  }
})
</script>
